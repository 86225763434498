.fabric-canvas {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .canvas-container {
    width: 100% !important;
    height: 100% !important;
    .lower-canvas,
    .upper-canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
