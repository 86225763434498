.btn-add-new {
  // height: 40px;
  font-size: 16px;
  display: flex;
  // justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  font-weight: 400;
  padding-left: 20px;
  padding-right: 20px;
  // @media screen and (min-width: 1024px) {
  //     height: 45px;
  // }
}

.btn-publish {
  border: 1px solid #00be39;
  color: #00be39;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #d6ffe2;
}

.btn-unpublish {
  border: 1px solid #2c3a57;
  color: #2c3a57;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
}

.btn_save {
  border: 1px solid #2c3a57;
  color: #2c3a57;
  font-weight: 500;
  padding-left: 30px;
  padding-right: 30px;
}

.btn-all-day {
  border: 1px solid #e5e7eb;
  color: #2c3a57;
  font-weight: 500;
  padding-left: 25px;
  padding-right: 25px;
}

.btn-approve-all {
  border: 1px solid #00be39;
  background-color: #00be39;
  color: #fff;
  font-weight: 500;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 16px;
}

.btn-reject-all {
  border: 1px solid #f80000;
  background-color: #f80000;
  color: #fff;
  font-weight: 500;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 16px;
}

.btn-set-to-group {
  border: 1px solid #f80000;
  background-color: #f80000;
  color: #fff;
  font-weight: 500;
  height: 43px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #f80000;
    color: #fff;
  }
  img {
    width: 20px;
    margin-right: 8px;
  }
}
