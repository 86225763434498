.btn_cancel {
  letter-spacing: 0px;
  background: #e8e8e8 0% 0% no-repeat padding-box;
  text-transform: uppercase;
  opacity: 1;
  height: 45px;
  font-weight: 500;
  min-width: 90px;
  font-size: 16px;
  @media (min-width: 768px) {
    min-width: 150px;
  }
  @media (max-width: 350px) {
    font-size: 14px;
  }
}

.btn_ok {
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  height: 45px;
  font-weight: 500;
  font-size: 16px;
  min-width: 90px;
  @media (min-width: 768px) {
    min-width: 150px;
  }
  @media (max-width: 350px) {
    font-size: 14px;
  }
}

.btn_custom_modal {
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  height: 45px;
  font-weight: 500;
  font-size: 16px;
  min-width: 90px;
  border: none;
}
