.master-layout {
  .table-header-flex {
    background: white;
    display: flex;
    align-items: center;
    @media (min-width: 768px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 0;
      min-height: 45px;
    }
    @media (min-width: 1200px) {
    }
    button,
    input,
    select {
      height: 45px;
    }
    input {
      background-color: transparent;
    }
    .ant-select-selector {
      height: 45px !important;
      input {
        height: 100% !important;
      }
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        line-height: 45px;
      }
    }
    .btn-search {
      background-color: #ffe5e5;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-input-group-compact {
      display: flex;
      align-items: center;
    }
    .bd-form-search {
      min-width: 200px;
      border-right: 0;
      @media screen and (min-width: 1024px) {
        min-width: 300px;
      }
    }
  }
  .table-header-style {
    $table-header-height: 45px;
    // margin: 0 12px 0;
    background-color: transparent;
    .table-total {
      font-size: 18px;
      color: #666666;
      width: 150px;
      // margin-bottom: 0.5rem;
    }
    .table-actions {
      width: 100%;
      @media screen and (min-width: 1200px) {
        width: calc(100% - 150px);
      }
    }
    form {
      .ant-select-selector,
      .ant-select-selector .ant-select-selection-search,
      .ant-select-selector .ant-select-selection-search input,
      .ant-select-selector .ant-select-selection-placeholder,
      .ant-input,
      .ant-input-search-button,
      .ant-select-selection-item,
      .btn-search {
        height: 40px;
        line-height: 40px;
        border-color: #e5e7eb;
        @media screen and (min-width: 1024px) {
          height: 45px;
          line-height: 45px;
        }
      }
      .ant-input-search-button,
      .btn-search {
        width: 45px;
        background-color: #ffe5e5;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: #fcd2d2;
        }
      }
      .ant-input {
        background-color: white;
      }
      .ant-form-item {
        margin-right: 1rem;
        margin-left: 0;
        &:last-child {
          margin-right: 0;
        }
        @media screen and (min-width: 1200) {
          margin-right: 0;
          margin-left: 1rem;
          &:first-child {
            margin-left: 0;
          }
        }
      }
      .input_search_header {
        min-width: 200px;
        @media (min-width: 1024px) {
          min-width: 300px;
        }
      }
    }
  }
  .table-row-height {
    height: 100px;
  }
  .table-row-white {
    background-color: white;
  }
  .table-row-gray {
    background: #f6f6f6 0% 0% no-repeat padding-box;
  }
  .btn_device_code_table {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e5e7eb;
    border-radius: 3px;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    width: 120px;
    height: 30px;
    padding: 0;
    span {
      line-height: 1.5rem;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .cell-device-info {
    .dot-status {
      width: 5px;
      height: 5px;
      border-radius: 100%;
      width: 12px;
      height: 12px;
      flex-shrink: 0;
      margin-right: 5px;
      display: inline-block;
      &.not-connect {
        background-color: #999999;
      }
      &.off-connect {
        background-color: #ff0a0a;
      }
      &.on-connect {
        background-color: #00be39;
      }
      &.lost-connect {
        background-color: #ff9b04;
      }
      &.no-playlist {
        background-color: #000;
      }
      &.on-connect-with-screen-on {
        background-color: #00be39;
      }
      &.on-connect-with-screen-off {
        background-color: #ff9b04;
      }
    }
    .device-name {
      color: #000000;
      font-weight: 500;
      line-height: 1;
    }
    .device-time {
      padding-left: 0;
      li {
        list-style: none;
        color: #666666;
        font-size: 12px;
        .value {
          color: #333333;
          font-weight: 500;
          word-break: break-word;
        }
      }
    }
    .dot {
      width: 5px;
      height: 5px;
      background: #666666 0% 0% no-repeat padding-box;
      border-radius: 50%;
    }
    .screen,
    .model {
      text-align: left;
      font-size: 14px;
      letter-spacing: 0px;
      color: #666666;
      opacity: 1;
    }
    .color_content {
      color: #333333;
    }
  }
  .color-text-table-default {
    color: #000000;
  }
  .btn-export {
    height: 40px;
    font-size: 16px;
    display: flex;
    justify-content: space-around;
    background: #eef0f4;
    align-items: center;
    font-weight: 500;
    border: none;
    margin-right: 10px;
    margin-left: 10px;
    text-transform: uppercase;
    @media screen and (min-width: 768px) {
      width: 150px;
    }
    @media screen and (min-width: 1024px) {
      height: 45px;
    }
  }
  .ant-table-content {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 5px #00000008;
    border: 1px solid #00000012;
  }
  .ant-table-container {
    color: #000;
    .ant-table-thead {
      > tr > th {
        background-color: #f3f4f9;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: normal;
        color: #666666;
        padding-top: 12px;
        padding-bottom: 12px;
        @media screen and (min-width: 1366px) {
          font-size: 14px;
        }
      }
    }
  }
  .ant-table-expanded-row-fixed {
    width: unset !important;
  }
  .ant-table-wrapper {
    &.table-striped-rows {
      tr:nth-child(2n) {
        background-color: #f6f6f6;
      }
      .ant-table-scroll-horizontal {
        tr:nth-child(2n) {
          background-color: #fff;
        }
        tr:nth-child(2n + 1) {
          background-color: #f6f6f6;
        }
      }
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }
    .ant-pagination-item {
      color: #666;
      border: 1px solid #a8a8a8;
    }
    .ant-pagination-item-active {
      color: #000;
      border: 1px solid #000;
      a {
        color: #000;
      }
    }
    .ant-pagination-disabled,
    .ant-pagination-disabled:hover,
    .ant-pagination-disabled:focus-visible {
      button {
        border: 1px solid #a8a8a8;
      }
    }
    .ant-pagination-item-link {
      color: #000;
    }
  }
  .flex-2xl {
    @media (min-width: 1536px) {
      display: flex !important;
    }
  }
  .flex-end-2xl {
    @media (min-width: 1536px) {
      justify-content: flex-end !important;
    }
  }
}

// Assign staff table
.master-layout {
  .add-more-table {
    .ant-table-wrapper {
      thead {
        > tr {
          background-color: transparent;
          border-bottom: 1px solid #00000008;
          border-collapse: collapse;
          > th {
            font-weight: 500;
            background-color: transparent;
            color: #000;
            border-bottom: 0;
            padding-left: 8px;
          }
        }
      }
      &.table-striped-rows {
        thead tr {
          background-color: transparent;
        }
      }
      .ant-table-content {
        border: none;
        td {
          padding-left: 8px;
        }
      }
    }
  }
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > td,
.ant-table tr td > div {
  vertical-align: text-top;
}
