.drawer-choose_layout {
  .ant-drawer-header {
    padding: 24px;
    .ant-drawer-title {
      font-size: 24px;
      font-weight: 500;
      .gr_name {
        color: $primary-color;
        font-weight: bold;
        line-height: 32px;
      }
    }
  }
  .ant-drawer-body {
    padding: 24px 40px;
  }
  .ant-drawer-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 25px;
    padding-right: 40px;
    padding-left: 40px;
    border-top: 0;
  }
  .item {
    .ant-radio-wrapper {
      margin-right: 0;
    }
    .ant-radio-inner {
      width: 18px;
      height: 18px;
      &::after {
        top: 2px;
        left: 2px;
        width: 12px;
        height: 12px;
      }
    }
    .view-layout {
      width: 100%;
      height: 290px;
      border: 1px solid #e5e7eb;
      border-radius: 3px;
      position: relative;
      cursor: pointer;
      &.active {
        border-color: $primary-color;
      }
      .bg-layout {
        height: 100%;
        padding-bottom: 50px !important;
        .layout-1 {
          width: 100%;
          padding-bottom: 56.25%;
          background-color: #77829a;
          border-radius: 5px;
        }
        .layout-2 {
          height: 95%;
          width: 50%;
          background-color: #77829a;
          border-radius: 5px;
        }
        .layout-3 {
          padding-bottom: 100%;
          background-color: #77829a;
          border-radius: 5px;
        }
        .layout-4 {
          width: 50%;
          padding-bottom: 40%;
          background-color: #77829a;
          border-radius: 5px;
        }
      }
      p {
        height: 35px;
        position: absolute;
        bottom: 0;
        margin-bottom: 0;
        text-align: center;
        width: 100%;
        font-weight: 500;
      }
    }
  }
}

.drawer-schedule_type {
  .ant-drawer-title {
    font-size: 24px;
  }
  .ant-drawer-content {
    overflow: hidden;
  }
  transform: translateX(0) !important;
  .ant-drawer-header {
    .gr_name {
      color: #0072f5;
      font-weight: 500;
    }
  }
  .ant-drawer-content-wrapper {
    width: 92% !important;
    @media (min-width: 800px) {
      width: 768px !important;
    }
  }
  .select-type {
    .ant-radio-group {
      gap: 20px;
      .ant-radio-wrapper {
        font-size: 18px;
      }
    }
  }
  .ant-drawer-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    border-top: 0;
  }
  label.key {
    color: $color-gray;
    font-weight: 500;
    font-size: 18px;
  }
}

.day-of-month {
  bottom: 0;
  .ant-modal-header {
    user-select: none;
    .ant-drawer-title {
      font-weight: 500;
      font-size: 20;
    }
  }
  .ant-drawer-body {
    padding-bottom: 0;
  }
  .ant-drawer-footer {
    border-top: 1px solid #f0f0f0;
  }
  .days {
    user-select: none;
    cursor: crosshair;
    .day {
      width: 55px;
      height: 55px;
      border: 2px solid #c9c9c9;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #666666;
      font-size: 18px;
      font-weight: 500;
      &.selected {
        border: 1px solid #f80000;
      }
    }
    .btn-select-all,
    .btn-clear-all {
      padding-left: 20px;
      padding-right: 20px;
      height: 55px;
      font-weight: 500;
      color: #666666;
    }
  }
  .ant-modal-footer {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
