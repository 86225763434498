::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}

$height-list-frame: 160px;
.playlist-page {
  .btn-success {
    background-color: #00be39;
  }
  .group_btn {
    align-items: center;
    display: flex;
    margin-top: 15px;
  }
  .btn-submit {
    background-color: #0072f5;
  }
  .btn-submit-preview {
    background-color: #00be39;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  #playlists-tab {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e0e0e0;
    border-radius: 3px 3px 0px 0px;
    opacity: 1;
    > div {
      border-right: 1px solid #eee;
      color: #333;
      opacity: 0.3;
      padding: 0.5rem 1.5rem;
      font-weight: bold;
      cursor: pointer;
      &:last-child {
        border-right: none;
      }
      &.active {
        opacity: 1;
      }
    }
  }
}

.btn-header {
  min-width: 180px;
  height: 45px;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  text-transform: uppercase;
}

.btn-back {
  font-size: 16px;
  letter-spacing: 0px;
  color: #666666;
  background: transparent;
  border: none;
  height: 45px;
  &:hover,
  &:focus,
  &:active {
    background: transparent !important;
  }
}

.text-white {
  color: white;
}

.text-gray {
  color: #666666;
}

.bd-bottom {
  border-bottom: 1px solid #00000021;
}

.bd-radius-5 {
  border-radius: 5px;
}

.bd-radius-10 {
  border-radius: 10px;
}

.circle-picker {
  span span > div {
    border: 1px solid #ab9a9a;
  }
}

.form-schedule-item {
  .form-schedule-item-content {
    display: flex;
    flex-direction: row;
  }
  .form-schedule-item-close {
    display: inline;
    margin-left: 10px;
    margin-top: 8px;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.modal-resolution {
  .title {
    text-align: left;
    font-size: 18px;
    letter-spacing: 0px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 12px;
    margin-right: 20px;
  }
  .block_resolution {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;
    .list-frame-layout {
      align-content: space-between;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 368px;
      @media (min-width: 420px) {
        display: flex;
      }
      .frame-layout {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 169px;
        height: 120px;
        background: #f9f9f9 0% 0% no-repeat padding-box;
        border: 1px solid #e0e0e0;
        border-radius: 3px;
        &.active {
          border: 1px solid #f80000;
          .name,
          .resolution {
            color: #333333;
          }
        }
        .name {
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0px;
          color: #999999;
          margin-bottom: 0px;
        }
        .resolution {
          text-align: center;
          font-size: 16px;
          letter-spacing: 0px;
          color: #999999;
          margin-bottom: 0px;
        }
      }
      .frame-layout:last-child {
        @media (max-width: 419px) {
          margin-top: 16px;
        }
      }
    }
  }
  .block_frame_orientation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
    .title {
      margin-bottom: 0px;
    }
  }
  .block_screen {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .title {
      margin-bottom: 0px;
    }
  }
  .width_select_radio {
    width: 368px;
    .ant-radio-wrapper {
      width: 170px;
      span {
        font-size: 18px;
        letter-spacing: 0px;
        color: #333333;
        text-transform: capitalize;
      }
    }
  }
}

.list_frame_orientation {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  .frame-orientation {
    cursor: pointer;
    display: flex;
    align-items: center;
    .name {
      margin-bottom: 0px;
      text-align: left;
      font-size: 14px;
      letter-spacing: 0px;
      color: #999999;
      text-transform: capitalize;
    }
    &.active {
      .name {
        color: #333333;
      }
    }
  }
}

$min-width: 1024px;
.playlist-page {
  .header_playlist {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .container_input_name {
      .ant-form-item-control {
        position: relative;
        .ant-form-item-explain-error {
          position: absolute;
          bottom: -20px;
          font-size: 14px;
        }
      }

      .input_name_playlist {
        text-align: left;
        font-size: 20px;
        padding-left: 0px !important;
        font-weight: bold;
        letter-spacing: 0px;
        box-shadow: unset !important;
        color: #000000;
        border: unset !important;
        background: white;
        cursor: pointer;
        min-width: 300px;
      }

      .action {
        text-align: left;
        font-size: 14px;
        letter-spacing: 0px;
        color: #999999;
        padding: 0px 11px;
      }
    }
  }
  .preview-right {
    overflow: hidden;
    margin-top: 1rem;
    .media-item-image {
      height: 80px !important;
      flex-direction: row;
      display: flex;
      .media-image {
        width: 80px !important;
        height: 80px !important;
        margin-right: 5px;
        object-fit: contain;
      }
    }
  }
  .playlist-col {
    position: relative;
    max-width: 100%;
    min-height: 1px;
    padding-left: 8px;
    padding-right: 8px;
    &.playlist-col-right {
      flex: 1 0 100%;
      margin-top: 20px;
      max-height: 108vh;
      overflow-y: scroll;
      background: white;
      span {
        font-size: 13px;
      }
      .button-action {
        width: 100%;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 50% 50%;
        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #e0e0e0;
          height: 62px;
          font-size: 12px;
          font-weight: 500;
          img {
            margin-bottom: 2px;
          }
          cursor: pointer;
          &:hover {
            background: #f1eeee 0% 0% no-repeat padding-box;
          }
        }
      }
      .right-playlist {
        .ant-card {
          height: 100%;
          overflow: hidden;
          .ant-card-head {
            height: 57px;
          }
          .ant-card-actions {
            height: 80px;
            li {
              text-align: left;
              padding-left: 15px;
            }
          }
          .ant-card-body {
            overflow: auto;
          }
        }
        @media screen and (min-width: $min-width) {
          height: 100%;
          // .ant-card {
          //   height: calc(100% - 1rem - 72px); // khong doi
          // }
        }
      }
      .list-attribute {
        .item_resolution,
        .item_duration,
        .item_bg {
          padding-bottom: 20px;
        }
        .item_bg {
          .attr-title {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0px;
            color: #999999;
            text-transform: uppercase;
          }
        }
        .item_resolution,
        .item_duration {
          .attr-title {
            text-align: left;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0px;
            color: #333333;
          }
        }
        .item_resolution {
          .label {
            font-size: 13px;
            letter-spacing: 0px;
            color: #999999;
            margin-right: 30px;
          }
          .value {
            text-align: left;
            font-size: 13px;
            letter-spacing: 0px;
            color: #333333;
          }
        }
        .item_duration {
          width: 100%;
          .ant-input-number {
            width: 100%;
            input {
              background-color: #fff;
            }
          }
        }
      }
    }
    &.playlist-col-center {
      max-width: 0%;
      .frame-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        .playlist-taskbar {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          ul {
            display: flex;
            align-items: center;
            padding: 0;
            width: 100%;
            list-style: none;
            li {
              padding-right: 15px;
              cursor: pointer;
              &:hover {
                color: #c8171e;
              }
              &.active {
                color: #c8171e;
              }
              &.pointer-none {
                pointer-events: none;
              }
            }
          }
        }
        .frame-block {
          width: 100%;
          display: block;
          align-items: center;
          background-color: #4a4a4a;
        }
      }
      .list_frame_container {
        width: 100%;
        height: $height-list-frame;
        display: flex;
        .group-actions {
          flex: 0 0 60px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          border-right: 2px solid rgba(211, 211, 211, 0.664);
        }
        .list_frame {
          width: 100%;
          height: 100%;
          display: flex;
          overflow-x: auto;
          overflow-y: hidden;
          padding: 10px;
          .frame_item_container {
            display: flex;
            flex-direction: column;
            flex: 0 0 145px;
            height: 100%;
            user-select: none;
            margin-right: 10px;
            padding: 6px 0px;
            align-items: flex-start;
            overflow: hidden;
            .title {
              text-align: center;
              font-weight: 500;
              font-size: 14px;
              letter-spacing: 0px;
              color: #666666;
              margin-bottom: 0px;
              > * {
                font-size: 14px;
                color: #666666;
              }
            }
          }
          .frame_item {
            background-color: #fff;
            overflow: hidden;
            height: 97px;
            width: 100%;
            border-radius: 6px;
            border: 2px solid #eee;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            &.active {
              border-color: #f80000;
              color: #f80000;
              .title {
                color: #f80000;
              }
            }
          }
        }
      }
    }
    &.playlist-col-left {
      max-width: 50% !important;
      flex: 1;
    }
    &.playlist-col-right {
      flex: 1;
      margin-top: 0px;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    @media (min-width: 768px) and (max-width: 1200px) {
    }
    @media (min-width: 1201px) {
      &.playlist-col-left {
        flex: 1;
        max-width: 50% !important;
      }
      &.playlist-col-right {
        flex: 1;
        margin-top: 0px;
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
      &.playlist-col-center {
        flex: 1;
      }
    }
  }
}

.playlist-status {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}

.list-media-playlist-container {
  width: 100%;
  min-height: 100% !important;
  border-right: 2px solid #ddd;
  padding-bottom: 20px;
  .breadcrumb_media {
    min-height: 50px;
    background: #f3f4f9 0% 0% no-repeat padding-box;
    border-radius: 3px 3px 0px 0px;
    display: flex;
    align-items: center;
    padding: 8px 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    .row-breadcrumb {
      display: flex;
      .btn-search {
        background: #ffe5e5 !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
    @media screen and (max-width: 1100px) {
      padding: 8px 10px !important;
      .row-breadcrumb {
        display: block;
        .ant-form-inline {
          margin-top: 10px;
        }
      }
    }
    .buttonHeader {
      .upload-btn {
        margin-left: 2px;
        font-size: 13px;
        font-weight: 400 !important;
      }
      .icon-btn {
        height: 18px;
      }
    }
    .ant-breadcrumb {
      display: flex;
      flex-wrap: wrap;
      margin-right: 20px;
    }
    .view_group {
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px 0px;
      img {
        cursor: pointer;
      }
    }
    .breadcrumb_title {
      word-break: break-all;
    }
    span {
      text-align: left;
      font-size: 16px;
      letter-spacing: 0px;
      color: #333333;
      text-transform: capitalize;
      opacity: 1;
      font-weight: bold;
    }
  }
  .media-list {
    width: 100%;
    padding: 10px;
    background-color: white;
    height: 100vh;
    overflow: hidden;
    @media screen and(max-width: 800px) {
      padding: 10px !important;
    }
    .media-grid-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .media-item-checked {
        background: #f3f4f9 0% 0% no-repeat padding-box;
        border-radius: 5px;
        border: 1px solid #e3e3e3;
      }
      .div-class-media {
        max-width: 160px;
        min-width: 140px;
        flex: 1 1;
      }
      .media-item {
        user-select: none;
        background: white;
        padding: 10px;
        &:hover {
          cursor: pointer;
        }
        .dropdown-list {
          position: relative;
          top: 50px;
          left: 100px;
        }
        .buttonDropdown {
          background-color: cyan;
          width: 200px;
        }
        .media-image {
          width: 100%;
          height: 120px;
          align-items: center;
          justify-self: center;
          justify-content: center;
          display: flex;
          .ant-image {
            position: relative;
            padding-bottom: 73%;
            overflow: hidden;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 40%;
            background-color: #f5f4f4;
            height: 100%;
            width: 100%;
            img {
              left: 0;
              width: 100%;
              top: 50%;
              transform: translateY(-50%);
              position: absolute;
            }
          }
        }
        .viewFileName {
          margin-top: 5px;
          padding: 0px 8px;
          text-align: center;
          font-size: 14px;
          letter-spacing: 0px;
          color: #333333;
          margin-bottom: 0px;
          word-wrap: break-word;
        }
      }
    }
    .media-list-content {
      word-break: break-all;
      min-height: inherit;
      .media-item {
        display: flex;
        align-items: center;
        background: white;
        padding: 12px 0px;
        border-bottom: 1px solid #e0e0e0e0;
        user-select: none;
        &:hover {
          cursor: pointer;
        }
        .dropdown-list {
          position: relative;
          top: 10px;
          left: 250px;
        }
        .media-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          min-width: 80px;
          align-items: center;
          justify-self: center;
          justify-content: center;
          display: flex;
          @media (max-width: 768px) {
            width: 60px;
            min-width: 60px;
            height: 60px;
          }
          .ant-image {
            position: relative;
            padding-bottom: 50%;
            overflow: hidden;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 40%;
            background-color: #f5f4f4;
            height: 100%;
            width: 100%;
            img {
              left: 0;
              width: 100%;
              top: 50%;
              transform: translateY(-50%);
              position: absolute;
            }
          }
        }
        .info-media {
          display: flex;
          flex-direction: column;
          width: 100%;
          flex-wrap: wrap;
          @media (min-width: 768px) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }
          .info_1 {
            display: grid;
            padding-right: 10px;
            flex-direction: column;
            p {
              padding-right: 10px;
              margin-bottom: 0px;
            }
          }
          .info_2 {
            min-width: 100px;
            display: flex;
            flex-direction: column;
            p {
              margin-bottom: 0px;
            }
          }
        }
        .viewFileName {
          line-height: 1.4rem;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .viewFileType-px {
          color: #77829a;
          font-size: 14px;
        }
        .viewFileName,
        .viewFileType,
        .viewFileSize,
        .viewFileCreateAt {
          margin-bottom: 0px;
          font-size: 16px;
          letter-spacing: 0px;
          color: #333333;
          margin-bottom: 0px;
          word-wrap: break-word;
          width: 100%;
        }
      }
      .media-item-checked {
        background: #f3f4f9 0% 0% no-repeat padding-box;
        border-radius: 5px;
        .viewFileName {
          color: #f80000 !important;
          font-weight: bold;
        }
      }
    }
  }
}

.modal-assign-group {
  width: 60% !important;
  @media screen and(min-width: 1300px) {
    width: 40% !important;
  }
  .ant-modal-content {
    .ant-modal-body {
      padding: 0px !important;
    }
  }
  .ant-modal-footer {
    padding: 20px 24px;
  }
  .header-container {
    padding: 20px 24px;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #eeeeee;
    .title {
      color: #333333;
      font-size: 24px;
      font-weight: 500;
    }
  }
  .form-input {
    border-bottom: 1px solid #eeeeee;
    padding: 20px 24px;
    .total-group {
      font-size: 16px;
      margin-top: 20px;
      text-align: right;
      color: #2c3a57;
    }
    .total-group-num {
      margin-right: 5px;
      color: red;
    }
  }
  .header-container-input {
    align-items: center;
    display: flex;
  }
  .contaier-body {
    flex: 1;
    overflow: hidden;
    .height-body {
      height: 300px;
      padding: 0px 24px 20px 24px;
      overflow-y: auto;
      .row-item {
        padding: 20px 0px;
        border-bottom: 1px solid #eeeeee;
      }
    }
  }
  .radio-parent {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    padding: 3px;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: width 0.4s linear;
    border: 1px solid #77829a;
    &-active {
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: 20px;
      padding: 3px;
      border: 1px solid red;
      transition: all 0.4s linear;
    }
  }
  .active-rounded {
    width: 1px;
    height: 1px;
    border-radius: 15px;
    transition: width 0.4s linear;
    &-active {
      width: 100%;
      height: 100%;
      border-radius: 15px;
      background-color: red;
      transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
}

.modal-assign-play-list {
  width: 60% !important;
  @media screen and(min-width: 1300px) {
    width: 60% !important;
  }
  .ant-modal-content {
    .ant-modal-body {
      padding: 0px !important;
    }
  }
  .ant-modal-footer {
    padding: 20px 24px;
  }
  .header-container {
    padding: 20px 24px;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #eeeeee;
    .title {
      color: #333333;
      font-size: 24px;
      font-weight: 500;
    }
  }
  .form-input {
    border-bottom: 1px solid #eeeeee;
    padding: 20px 24px;
    .total-group {
      font-size: 16px;
      margin-top: 20px;
      text-align: right;
      color: #2c3a57;
    }
    .total-group-num {
      margin-right: 5px;
      color: red;
    }
  }
  .header-container-input {
    align-items: center;
    display: flex;
  }
  .contaier-body {
    flex: 1;
    overflow: hidden;
    .height-body {
      height: 300px;
      padding: 0px 24px 20px 24px;
      overflow-y: auto;
      .row-item {
        padding: 20px 0px;
        border-bottom: 1px solid #eeeeee;
      }
    }
  }
  .radio-parent {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    padding: 3px;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: width 0.4s linear;
    border: 1px solid #77829a;
    &-active {
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: 20px;
      padding: 3px;
      border: 1px solid red;
      transition: all 0.4s linear;
    }
  }
  .active-rounded {
    width: 1px;
    height: 1px;
    border-radius: 15px;
    transition: width 0.4s linear;
    &-active {
      width: 100%;
      height: 100%;
      border-radius: 15px;
      background-color: red;
      transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
}
