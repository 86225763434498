.modal_confirm_custom {
  width: 680px !important;
  .ant-modal-header {
    padding: 18px 30px;
    .ant-modal-title {
      font-size: 24px;
      letter-spacing: 0px;
      color: #333333;
      // text-transform: capitalize;
      opacity: 1;
    }
  }
  .ant-modal-body {
    padding: 40px 30px;
    .content_modal {
      text-align: center;
      font-size: 20px;
      letter-spacing: 0px;
      color: #333333;
    }
  }
  .ant-modal-footer {
    padding: 30px;
  }
  .bg_input_form {
    input {
      background: #f9f9f9 0% 0% no-repeat padding-box;
    }
    .ant-select-selector,
    .ant-input-password {
      background: #f9f9f9 0% 0% no-repeat padding-box;
    }
  }
  .title_content {
    font: normal normal medium 18px/24px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 500;
    text-transform: capitalize;
    opacity: 1;
    font-size: 18px;
  }
  // .ant-radio {
  //   -ms-transform: scale(1.5);
  //   -moz-transform: scale(1.5);
  //   -webkit-transform: scale(1.5);
  //   -o-transform: scale(1.5);
  //   transform: scale(1.5);
  // }
  // .ant-radio-checked .ant-radio-inner {
  //   border: 3px solid #ffd8d8;
  // }
  // .ant-radio-inner::after {
  //   top: -1px;
  //   left: -1px;
  //   width: 11px;
  //   height: 11px;
  // }
  // .ant-radio-checked {
  //   .ant-radio-input:focus {
  //     border-color: transparent !important;
  //     border: none !important;
  //     box-shadow: none !important;
  //     outline: 0px;
  //     outline-width: 0;
  //   }
  // }
  .input_edit_device {
    width: 100%;
    @media (min-width: 768px) {
      width: 85%;
    }
  }
}

.width_modal_add_device {
  width: 90% !important;
  max-width: 755px !important;
}

.modal_device_info_custom {
  width: 90% !important;
  max-width: 860px !important;
  .ant-modal-body {
    padding: 1rem;
    overflow: hidden;
  }
  .ant-modal-footer {
    padding: 1rem;
  }
  .custom_footer_modal {
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .group-btn {
        margin-top: 0px !important;
      }
    }
    .group-btn {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      .btn_refresh_app {
        margin-left: 0px;
        margin-top: 16px;
      }
      @media (min-width: 500px) {
        .btn_refresh_app {
          margin-left: 20px;
          margin-top: 0px;
        }
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        text-align: left;
      }
    }
  }
  .rotate-btn {
    .title {
      text-align: left;
      font-size: 18px;
      letter-spacing: 0px;
      color: #333333;
      margin-right: 12px;
      font-weight: 500;
    }
  }
}

.modal_group_device_custom {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000000f;
  border-radius: 3px;
  padding-bottom: 0px;
  .btn-add-device {
    min-width: 91px;
    height: 45px;
    font-size: 16px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-transform: uppercase;
    background-color: #f80000;
    color: white;
  }
  .select-device {
    display: flex;
    justify-content: space-between;
  }
  .table_device {
    .ant-table-thead > tr > th {
      background-color: white;
      &::before {
        width: 0px !important;
      }
      border-bottom: 2px solid #dbdbdb;
      text-align: left;
      font-size: 14px;
      letter-spacing: 0px;
      color: #000000;
      text-transform: uppercase;
      opacity: 1;
    }
    .ant-table-tbody > tr > td {
      text-align: left;
      font-size: 18px !important;
      letter-spacing: 0px;
      color: #333333;
    }
  }
  .table-row-height {
    min-height: 60px;
  }
  .table-row-white {
    background-color: white;
  }
  .table-row-gray {
    background: #f6f6f6 0% 0% no-repeat padding-box;
  }
}

.modal_map_view {
  width: 731px !important;
  height: 80vh;
  max-height: 527px;
  .ant-modal-content,
  .ant-modal-body {
    height: 100%;
  }
  .ant-modal-body {
    padding: 0px;
    .container_view {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      .btn-close {
        cursor: pointer;
        position: absolute;
        top: 14px;
        right: 14px;
        font-size: 18px;
        letter-spacing: 0px;
        color: #666666;
      }
    }
  }
}

.modal_setting_deivce {
  .ant-form-item-label {
    font-weight: bold;
  }
  .select_wifi {
    @media (max-width: 650px) {
      // flex-direction: column;
    }
  }
  .btn_scan {
    background-color: #428ddc;
    border: 15px;
    color: white;
    min-width: 135px;
  }
  .container_input {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .label_input {
      width: 150px;
      height: 40px;
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      @media (min-width: 769px) {
        flex: 0 0 150px;
      }
    }
    .select_input {
      flex: 1;
      width: 100%;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

//ant modal custom
.ant-modal-content {
  .ant-modal-header {
    border-bottom: 1px solid $color-md-border;
  }
  .ant-modal-close {
    top: 5px;
    .ant-modal-close-x {
      color: $color-gray2;
      font-size: 22px;
    }
  }
}
.video-custom-waiting .spinner {
  display: block;
}
.video-progress {
  top: 10px;
  left: 10px;
}
.spinner {
  position: absolute;
  top: 40%;
  left: 48%;
  // display:none;
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: red;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  z-index: 999;
}

@keyframes spin {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
