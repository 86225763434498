$primary-color: #f80000;
$header-height: 60px;
$content-height: 100%;
$color-text-menu-item: #666666;
$menu-item-bg-active: #fff8f8;
$menu-border-color: #00000010;
$color-gray: #333333;
$color-gray2: #999999;
$color-white: #ffffff;
$color-md-input: #f7f9fc;
$color-md-border: #c3cfe2;
$bg-menu: #2c3a57;
.primary_color {
  color: $primary-color;
}

.color-333 {
  color: #333;
}

.mb-2rem {
  margin-bottom: 2rem;
}

.color-black {
  color: #000000;
}

.font-medium {
  font-weight: 500;
}

.font-14 {
  font-size: 14px;
}

.font-18 {
  font-size: 18px;
}

.font-24 {
  font-size: 24px;
}

.font-bold {
  color: red;
  font-weight: bold;
}

.text-red {
  color: $primary-color;
}

.flex-1 {
  flex: 1;
}

@media screen and (min-width: 1024px) {
  $header-height: 80px;
}
