.ant-form-item-label > label.ant-form-item-required::before {
  position: absolute;
  right: -10px;
  color: $primary-color;
}

.change-password-form {
  .ant-form-item-label > label.ant-form-item-required::before {
    position: relative;
    right: unset;
    color: $primary-color;
  }
}

.form-row-2-fields {
  @media screen and (min-width: 1200px) {
    > .ant-col:nth-child(2) {
      .ant-form-item-label {
        text-align: center;
      }
    }
  }
}

.card-form {
  height: 100%;
  box-shadow: 0px 5px 5px #00000008;
}

.bd-form-search {
  border: 1px solid #e5e7eb;
}
