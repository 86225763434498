.weekly-card {
  .ant-card-head-title {
    border-bottom: 1px solid #00000010;
    padding-bottom: 8px;
    margin-bottom: 1rem;
  }
  .ant-form-item-label > label {
    color: #000;
    font-size: 14px;
    text-transform: uppercase;
  }
}

.special-day-card {
  .ant-card-head-title {
    border-bottom: 1px solid #00000010;
    padding-bottom: 8px;
    margin-bottom: 1rem;
  }
  .ant-form-item-label > label {
    color: #000;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .add-special-day,
  .add-day-off {
    text-transform: uppercase;
    font-size: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }

  .add-special-day {
    color: #00be39;
    background-color: #f2fff6;
    border-color: #00be39;
    &:hover {
      background-color: #cdf5d9;
    }
  }
  .add-day-off {
    background-color: #dbecff;
    border-color: #00be39;
    color: #0072f5;
    &:hover {
      background-color: #bfd9f8;
    }
  }

  .choose_time_box {
    > .ant-col {
      position: relative;
      > .ant-form-item-explain-error {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
}
