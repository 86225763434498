.ant-popover-inner-content {
  // padding: 0 !important;
}
.schedule-group-device {
  .ant-card {
    .ant-card-head-title {
      font-weight: 500;
      color: $color-gray;
      font-size: 24px;
    }
  }
  .small-title {
    color: $color-gray;
    font-size: 18px;
    font-weight: 500;
  }
  .schedule-header {
    .view-map {
      color: #0072f5;
      cursor: pointer;
      position: relative;
      margin-left: 10px;
      &::after {
        content: '';
        position: absolute;
        bottom: 1px;
        width: 100%;
        height: 1px;
        left: 0;
        background-color: #0072f5;
      }
    }
  }
  .schedule-group-header {
    display: flex;
    margin-bottom: 10px;
    .back-action {
      flex-basis: 100%;
      overflow: hidden;
      background-color: $color-white;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @media (min-width: 768px) {
        flex-basis: 52%;
        margin-right: 10px;
      }
      .group-name {
        font-weight: bold;
        font-size: 24px;
        padding-left: 20px;
      }
    }
    .time-zone {
      flex-basis: 100%;
      background-color: $color-white;
      overflow: hidden;
      @media (min-width: 768px) {
        flex-basis: 48%;
        margin-left: 10px;
      }
    }
  }
  .schedule-content {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    @media (min-width: 992px) {
      flex-direction: row;
    }
    .playlist {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 5px 5px #00000008;
      border-radius: 3px;
      overflow: hidden;
      @media (min-width: 992px) {
        flex-basis: 52%;
        margin-right: 10px;
      }
      .ant-card-head-title {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .select-playlist {
        display: flex;
        justify-content: space-between;
        .lb-screen {
          width: 165px;
          color: #0072f5;
          font-weight: 500;
          font-size: 20px;
        }
      }
      .btn-add-playlist {
        min-width: 91px;
        font-size: 16px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        text-transform: uppercase;
        background-color: #f80000;
        color: white;
      }
    }
    .table_schedule {
      .bg-header {
        background-color: #eef0f4;
      }
      .ant-table {
        min-width: unset;
      }
      .ant-table-thead > tr > th {
        background-color: #eef0f4;
      }
      .table-row-height {
        .ant-table-cell {
          border-bottom: 1px solid #e3e3e3;
          word-break: break-word !important;
        }
      }
      .ant-table-thead {
        th {
          background-color: #ffffff;
          border: 0;
          font-size: 14px;
          font-weight: 500;
          color: #000000;
        }
      }
      .ant-table-content {
        border: 0;
      }
      .ant-table-row {
        &.active {
          background-color: #eff6ff;
          .playlist-name {
            color: #0072f5;
            font-weight: 500;
          }
        }
      }
      .btn-schedule {
        color: #0072f5;
        font-size: 14px;
        border: 1px solid #0072f5;
        padding: 5px 8px;
        width: 110px;
      }
      .playlist-name {
        font-weight: 500;
      }
      .time {
        color: #000000;
      }
    }
    .group-right-content {
      overflow: hidden;
      position: relative;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 5px 5px #00000008;
      border-radius: 3px;
      margin-top: 20px;
      .ant-popover-arrow-content {
        background-color: unset !important;
      }
      @media (min-width: 992px) {
        flex-basis: 48%;
        margin-left: 10px;
        margin-top: 0;
      }
      .ant-card-head-title {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .label {
        color: #333333;
        font-weight: 500;
      }
      .playlist-layout {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        @media (min-width: 1200px) {
          flex-direction: row;
        }
        .screen-layout-left {
          width: 100%;
          max-width: 280px;
          @media (min-width: 1200px) {
            width: 40%;
          }
          border: 1px solid #e5e7eb;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          .bg-screen {
            width: 100%;
          }
        }
        .screen-layout-right {
          width: 100%;
          @media (min-width: 1200px) {
            width: 60%;
          }
          .btn-change-layout {
            color: #333333;
            font-weight: bold;
          }
          .color {
            width: 75px;
            height: 40px;
            margin-right: 10px;
            border-radius: 3px;
            position: relative;
            border: 0.5px solid #e5e7eb;
            cursor: pointer;
          }
          .react-color {
            position: absolute;
            top: 45px;
            z-index: 2;
          }
        }
      }
      .setting {
        .ant-radio-group-outline {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
          flex-wrap: wrap;
          gap: 15px;
          > div {
            width: 30%;
          }
          .ant-radio-wrapper {
            margin-bottom: 0.5rem;
            margin-right: 0;
          }
        }
      }
    }
    .schedule-type {
      position: relative;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 5px 5px #00000008;
      border-radius: 3px;
      margin-top: 20px;
      @media (min-width: 992px) {
        flex-basis: 48%;
        margin-left: 10px;
        margin-top: 0;
      }
      .company-name {
        color: #0072f5;
      }
      .list-type {
        .ant-radio-wrapper {
          font-size: 18px;
        }
      }
    }
  }
  .action {
    .btn-cancel {
      font-weight: 500;
      color: #000000;
      font-size: 16px;
      background-color: #c9c9c9;
      height: 45px;
      @media (min-width: 768px) {
        font-size: 18px;
      }
    }
    .btn-add-schedule {
      background-color: #0072f5;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      height: 45px;
      @media (min-width: 768px) {
        font-size: 18px;
      }
    }
  }
}

.month-modal {
  .ant-modal-header {
    user-select: none;
  }
  .days {
    user-select: none;
    cursor: crosshair;
    .day {
      width: 70px;
      height: 70px;
      border: 2px solid #c9c9c9;
      border-radius: 5px;
      margin: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #666666;
      font-size: 18px;
      font-weight: 500;
      &.selected {
        border: 1px solid #f80000;
      }
    }
  }
  .ant-modal-footer {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.landscape-full-screen {
  width: 100%;
  padding-bottom: 56.25%;
  border-radius: 5px;
  border: 0.5px solid #e5e7eb;
}

.portrait-full-screen {
  width: 50%;
  height: 100%;
  border-radius: 5px;
  border: 0.5px solid #e5e7eb;
}

.landscape-split-screen {
  width: 50%;
  padding-bottom: 56.25%;
  border-radius: 5px;
  border: 0.5px solid #e5e7eb;
  &.active {
    border: 2px solid #2c3a57;
  }
}

.portrait-split-screen {
  height: 43%;
  width: 50%;
  border-radius: 5px;
  border: 0.5px solid #e5e7eb;
  &.active {
    border: 2px solid #2c3a57;
  }
}

.schedule-type-content {
  &.schedule-monthly {
    .checkbox-group {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      .checkbox-item {
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        padding: 12px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.required {
          border-bottom: 1px solid #ff4d4f;
        }
        .time {
          color: #0072f5;
          margin-right: 30px;
        }
        .btn-set-time {
          border: 1px solid #0072f5;
          color: #0072f5;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          padding: 5px 15px;
        }
      }
    }
  }
  &.schedule-special-day {
    .btn-add-new-dashed {
      height: 70px;
      border-radius: 10px;
      color: $primary-color;
    }
    .day-item {
      .ant-space-item {
        width: 100%;
      }
      .btn-delete-day-item {
        color: $primary-color;
        display: flex;
        align-items: center;
        justify-content: j;
        border: 0;
        font-size: 16px;
        outline: 0;
        box-shadow: none;
        font-weight: 500;
        img {
          margin-right: 5px;
        }
      }
      .day-name {
        border: 0;
        outline: 0;
        background-color: transparent;
        padding-left: 0;
        font-size: 20px;
        font-weight: 500;
        &:active,
        &:focus {
          border: 0;
          box-shadow: none;
        }
        &::placeholder {
          color: #77829a;
          font-size: 20px;
          font-weight: 500;
        }
        &::-moz-placeholder {
          color: #77829a;
          font-size: 20px;
          font-weight: 500;
        }
      }
    }
  }
}
