.right-playlist-add-new {
  transition: all 0.5s linear cubic-bezier(0.075, 0.82, 0.165, 1);
  .play-list-header {
    height: 55px;

    background: #f3f4f9 0% 0% no-repeat padding-box;
    border-radius: 3px 3px 0px 0px;
    display: flex;
    align-items: center;
    padding: 8px 20px;
    justify-content: space-between;
    flex-direction: row;
  }
  .ant-input-number-handler-wrap,
  .ant-input-number-handler-up,
  .ant-input-number-handler-down {
    display: none;
  }
  .ant-input-number {
    border: unset !important;
    border-width: 0 !important;
    border-color: transparent !important;
    border-radius: none !important;
  }
  .media-modal-playlist {
    width: 70% !important;
    .media-container-page {
      max-height: 70vh;
      overflow-y: scroll;
      height: auto;
      @media (max-height: 700px) {
        max-height: 400px;
      }
    }
  }
  .play-list-right-item {
    min-height: 50px;
    border-radius: 3px 3px 0px 0px;
    background: white;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    padding: 8px 20px;
    justify-content: space-between;
    flex-direction: row;
    .url-input {
      .ant-form-item-control-input {
        min-height: 0px;
      }
    }
    .durationInput {
      .ant-form-item-explain {
        position: unset !important;
        bottom: unset !important;
      }
    }
    .ant-form-item-explain {
      position: absolute;
      bottom: -32px;
    }

    .view-edit-remove {
      flex: 1;
      flex-direction: row;
      margin-left: 5px;
      justify-content: flex-end;
      display: flex;
    }
    .media-item-image {
      flex: 4;
      flex-direction: row;

      display: flex;
      padding-right: 10;
      .media-item-title {
        word-break: break-all;
        font-size: 16px;
      }
      .media-item-des {
        word-break: break-all;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
          'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 14px;
        color: #77829a;
      }
      .media-image {
        width: 80px !important;
        height: 80px !important;
        align-items: center;
        justify-self: center;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-right: 10px;
        .ant-image {
          position: relative;
          overflow: hidden;
          background-repeat: no-repeat;
          justify-self: center;
          background-position: 50%;
          background-size: 40%;
          background-color: #f5f4f4;
          height: 100%;
          width: 100%;
          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
          }
        }
      }
    }
    .input_url {
      border: none !important;
      outline: -webkit-focus-ring-color auto 0px !important ;
      border-bottom: 1px solid #eeeeee !important;
      :focus,
      :focus-visible {
        box-shadow: none !important;
        border: none !important;
      }
    }
    .ant-input:focus {
      box-shadow: none !important;
    }

    .media-item-duration {
      flex: 2;
      // height: fit-content;
      .row-focus {
        border: 1px solid grey;
        border-radius: 3px;
        display: flex;
      }
      .available {
        margin-left: 5px;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/16px
          var(--unnamed-font-family-roboto);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-0072f5);
        text-align: left;
        font: normal normal normal 14px/16px Roboto;
        letter-spacing: 0px;
        color: #0072f5;
        opacity: 1;
      }
      .up-input {
        cursor: pointer;
        border-left: 1px solid grey;
        width: 20px;
        height: 20px;
      }
      .down-input {
        cursor: pointer;
        border-left: 1px solid grey;
        border-top: 1px solid grey;
        width: 20px;
        height: 20px;
      }
      .container-input-duration {
        border: 1px solid grey;
        display: flex;
        flex-direction: row;
        border-radius: 5px;

        input {
          :hover {
            border: unset;
          }
          :focus {
            border: unset;
          }
        }
      }
    }
  }
}
