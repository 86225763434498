@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto/Roboto-Regular.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto/Roboto-Light.ttf');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto/Roboto-Medium.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto/Roboto-Bold.ttf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

body {
  font-family: 'Roboto' !important;
}

.fw-500 {
  font-weight: 500;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}
