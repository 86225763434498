#sidebar {
  .ant-menu-item {
    height: 32px;
    line-height: 32px;
    span {
      font-size: 13px;
    }
    .anticon {
      font-size: 16px;
    }
  }
}

.fix-bottom {
  > .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
}

.category-list-submenu {
  > .ant-menu-sub {
    > .ant-menu-item {
      margin-bottom: 0;
    }
  }
  .ant-menu-inline-collapsed {
    width: 160px;
    padding: 0 0.5rem;
    .ant-menu-item {
      margin-bottom: 0;
      padding: 0;
      .category-name {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 55px;
      }
      // &.ant-menu-item-selected {
      //     background-color: transparent;
      // }
    }
  }
}

// .ant-menu-submenu {
//     .ant-menu-item-selected {
//         background-color: #c8171e !important;
//     }
// }
.ant-menu-submenu-popup {
  .ant-menu-inline-collapsed {
    width: 160px;
    padding: 0 0.5rem;
    .ant-menu-item {
      margin-bottom: 0;
      padding: 0;
      // &.ant-menu-item-selected {
      //     background-color: transparent;
      // }
    }
  }
}

.left-menu-sidebar {
  max-height: $content-height;
  overflow: auto;
  box-shadow: 1px 0px 2px #00000008;
  -moz-box-shadow: 1px 0px 2px #00000008;
  -webkit-box-shadow: 1px 0px 2px #00000008;
  -o-box-shadow: 1px 0px 2px #00000008;
  -ms-box-shadow: 1px 0px 2px #00000008;
  .logo {
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    box-shadow: 13px 3px 40px #222e4a1a;
    border-bottom: 1px solid $color-white;
    width: 100%;
    img {
      height: 45px;
    }
    @media screen and (min-width: 1024px) {
      height: 90px;
      img {
        height: auto;
      }
    }
    p {
      color: $color-white;
      font-size: 11px;
      margin-top: 5px;
      margin-bottom: 0;
    }
  }
  .left-menu-content {
    background-color: $bg-menu;
    .ant-menu-item {
      border: 0;
    }
  }
  .ant-layout-sider-children {
    display: flex;
    align-items: flex-start;
    // justify-content: space-between;
    flex-direction: column;
    .left-menu {
      height: calc(100vh - 100px);
      @media screen and (min-width: 1024px) {
        height: calc(100vh - 130px);
      }
      overflow: auto;
      overflow-x: hidden;
      width: 100%;
      border-right: 0;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #d9d9d9;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        border: 3px solid #ff00002e;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #d9d9d9;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 3px solid #d46060;
  }
  .ant-menu-sub {
    background: $color-white;
  }
  .ant-menu-item-group-title {
    padding-top: 18px;
    padding-left: 24px;
    opacity: 0.5;
    color: #ffffff;
    font-size: 16px;
  }
  .ant-menu-item-group-list {
    border-bottom: 1px solid $menu-border-color;
    .ant-menu-item {
      box-shadow: none;
      padding-top: 17px !important;
      padding-bottom: 17px !important;
      height: 63px;
      &::after {
        border-right: none;
      }
    }
    .ant-menu-title-content {
      font-size: 16px;
      color: #ffffff;
      margin-left: 20px;
      @media screen and (min-width: 1024px) {
        font-size: 18px;
      }
    }
    .sub-menu-heading {
      color: #ffffff !important;
      .ant-menu-title-content {
        font-size: 20px;
        color: #666;
      }
    }
  }
  .ant-menu-item {
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0px;
    margin-bottom: 0;
    padding-top: 17px;
    padding-bottom: 17px;
    height: 60px;
    border-bottom: 1px solid $menu-border-color;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &::after {
      border-right: none;
    }
    &:first-child {
      margin-bottom: 0;
    }
    &:not(:first-child),
    &:not(:last-child) {
      margin-bottom: 0;
      margin-top: 0;
    }
    .ant-menu-title-content {
      margin-left: 20px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 18px;
    }
  }
  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: transparent !important;
  }
  .ant-menu-item-selected {
    .ant-menu-title-content {
      font-weight: bold;
    }
  }
}

.ant-layout-sider-collapsed {
  &.left-menu-sidebar {
    .ant-menu-item-group-title {
      display: none;
    }
  }
}

.navigation-logout {
  padding-left: 24px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  border-top: 1px solid #99959542;
  width: 100%;
}

.app-version {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  border-top: 1px solid #fff;
  width: 100%;
  padding-top: 5px;
}

.ant-menu.ant-menu-inline-collapsed {
  .ant-menu-item,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
  .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 calc(50% - 28px / 2);
  }
}

.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed .anticon {
  min-width: 24px;
}

.login-as-company-admin {
  padding: 0 1rem;
  width: 100%;
  border-bottom: 1px solid #fff;
  display: flex;
  justify-content: center;
  .container_icon {
    display: flex;
    gap: 1rem;
    .title_btn {
      color: #00be39;
    }
  }
  .ant-menu-item-group-title {
    display: inline-block;
    padding: 0 0 0.5rem 0;
  }
}
